<template>
  <v-row>
    <!-- <v-col cols="12">
      <v-switch v-model="offer.active" :label="$t('active')"></v-switch>
    </v-col> -->

    <!-- <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="offer.name" required />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text
        vid="code"
        v-model="offer.code"
        :label="$t('code')"
        required
      />
    </v-col> -->

    <v-col cols="12" lg="4">
      <price-list-select v-model="priceListId" autoselect />
    </v-col>

    <v-col cols="12" lg="4">
      <currency-select v-model="currencyId" autoassign required />
    </v-col>

    <!-- <v-col cols="12" lg="4" v-if="withQuantity">
      <form-field-text
        input-type="number"
        vid="quantity"
        v-model="offer.quantity"
        rules="required|min:1"
        :label="$t('quantity')"
      />
    </v-col>
 -->
    <v-col cols="12" lg="4">
      <form-field-text
        v-model.number="price"
        :label="priceLabel"
        input-type="number"
        required
      />
    </v-col>

    <v-col v-if="withOldPrice" cols="12" lg="4">
      <form-field-text
        :label="'old.price'"
        :value="offer.old_price_value"
        input-type="number"
        rules=""
        vid="old_price"
        @input="$set(offer, 'old_price', $event)"
      />
    </v-col>

    <v-col v-if="offer && !!offer.id" cols="12">
      <sheet>
        <offer-prices-table :offer="offer" limit="4">
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title v-text="$t('latest.offer.prices')" />
            </v-toolbar>
          </template>
        </offer-prices-table>
      </sheet>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import type { Product, ProductData } from "@planetadeleste/vue-mc-shopaholic";
import { Offer } from "@planetadeleste/vue-mc-shopaholic";
import type { CompanySettingsData } from "@planetadeleste/vue-mc-gw";
import { PriceList } from "@planetadeleste/vue-mc-gw";

import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import PriceListSelect from "@/modules/pricelists/components/PriceListSelect.vue";
import OfferPricesTable from "@/modules/pricelists/components/OfferPricesTable.vue";
import { AppModule } from "@/store/app";

@Component({
  components: { CurrencySelect, PriceListSelect, OfferPricesTable },
})
export default class FormFieldOffer extends Vue {
  @VModel({ type: Object, default: () => new Offer({ quantity: 1 }) })
  offer!: Offer;
  @Prop(Object) readonly product!: Product | Partial<ProductData>;
  @Prop(Boolean) readonly withOldPrice!: boolean;
  @Prop(Boolean) readonly withQuantity!: boolean;

  obPriceList: PriceList | null = null;

  get sPriceField() {
    return this.priceWithTax ? "price_with_tax" : "price";
  }

  get price() {
    return this.offer.get(`${this.sPriceField}_value`);
  }

  set price(iPrice: number) {
    this.offer.set(this.sPriceField, iPrice);
    this.offer.set(`${this.sPriceField}_value`, iPrice);
  }

  get currencyId() {
    return this.offer.get("currency_id");
  }

  set currencyId(iValue: number) {
    this.offer.set("currency_id", iValue);
  }

  get priceListId(): number {
    return this.offer.get("price_list_id");
  }

  set priceListId(iValue: number) {
    this.offer.set("price_list_id", iValue);
  }

  get priceLabel() {
    const sPriceTitle = !this.priceWithTax ? "without" : "with";
    return `price.${sPriceTitle}.tax`;
  }

  get company() {
    return AppModule.company;
  }

  get settings(): Partial<CompanySettingsData> {
    return this.company.get("settings", {});
  }

  get priceWithTax() {
    return this.obPriceList ? this.obPriceList.price_with_tax : false;
  }

  async loadPriceList() {
    if (!this.priceListId) {
      return;
    }

    const obModel = new PriceList({ id: this.priceListId });
    await obModel.fetch();

    this.obPriceList = obModel;
  }

  @Watch("priceListId", { immediate: true })
  onChangePriceList(id: number) {
    if (id && this.obPriceList && id !== this.obPriceList.id) {
      this.loadPriceList();
    }
  }

  async mounted() {
    await this.loadPriceList();
  }
}
</script>
