<template>
  <v-data-table
    :disable-pagination="disableFooter"
    :headers="headers"
    :hide-default-footer="disableFooter"
    :items="arPrices"
    disable-sort
  >
    <template #top>
      <slot name="top" />
    </template>

    <template #[`item.price`]="{ item }">
      <v-chip
        :color="
          currentPrice && currentPrice.id == item.id
            ? 'green'
            : 'grey lighten-2'
        "
        label
        v-text="$_.get(item, sPriceField)"
      />
    </template>

    <template #[`item.created_at`]="{ item }">
      <span
        v-if="item.created_at"
        v-text="$dayjs(item.created_at).format('DD/MM/Y')"
      />
    </template>

    <template #[`item.valid_at`]="{ item }">
      <span
        v-if="item.valid_at"
        v-text="$dayjs(item.valid_at).format('DD/MM/Y')"
      />
    </template>

    <template #[`item.user`]="{ item }">
      <name-with-avatar
        v-if="item.audit_by"
        :name="item.audit_by.name"
        :path="item.audit_by.avatar"
      >
        <template #subtitle>
          <span
            v-if="item.audit_at"
            v-text="$dayjs(item.audit_at).format('DD/MM/Y hh:mm:ss')"
          />
        </template>
      </name-with-avatar>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import TaxTypeCacheMixin from "@/modules/taxtypes/mixins/TaxTypeCacheMixin";
import { currencyFormat } from "@/plugins/helpers";
import { AppModule } from "@/store/app";

import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import dayjs from "dayjs";
import type { OfferModel } from "@/types/utils";
import { PriceList } from "@planetadeleste/vue-mc-gw";
import { number } from "mathjs";
import { chain, get, isNumber, isString, map, set } from "lodash";

@Component({
  components: { NameWithAvatar },
})
export default class OfferPricesTable extends Mixins(
  DataTableMixin,
  TaxTypeCacheMixin
) {
  // @ts-ignore
  @Prop(Object) readonly offer!: OfferModel;
  @Prop([Number, String]) readonly limit!: number;

  arPrices: Record<string, any>[] = [];
  obPriceList: PriceList | null = null;

  get currency() {
    return this.offer ? this.offer.get("currency_id") : null;
  }

  get disableFooter() {
    return this.arPrices.length < 10;
  }

  get currentPrice() {
    const now = dayjs();

    return chain(this.arPrices)
      .orderBy("price_valid_at", "desc")
      .filter((obPrice) => {
        const obValidAt = obPrice.valid_at
          ? dayjs(obPrice.valid_at).startOf("day")
          : null;

        return obValidAt && now.isSameOrAfter(obValidAt);
      })
      .first()
      .value();
  }

  get sPriceField() {
    return this.priceWithTax ? "price_with_tax" : "price";
  }

  get priceListId(): number {
    return this.offer.get("price_list_id");
  }

  get priceWithTax() {
    return this.obPriceList ? this.obPriceList.price_with_tax : false;
  }

  async loadPriceList() {
    if (!this.priceListId) {
      return;
    }

    const obModel = new PriceList({ id: this.priceListId });
    await obModel.fetch();

    this.obPriceList = obModel;
  }

  mounted() {
    this.$nextTick(async () => {
      const headers: DataTableHeader[] = [
        { text: "amount", value: "price" },
        { text: "created.date", value: "created_at" },
        { text: "date.valid.from", value: "valid_at" },
        { text: "updated.by", value: "user" },
      ];
      this.setDTHeaders(headers).mapDTHeaders();

      await this.loadPriceList();
      await this.loadTaxTypes();
      await this.loadPrices();
    });
  }

  async loadPrices() {
    if (!this.offer) {
      return;
    }

    const obData = { id: this.offer.id };

    if (this.limit) {
      set(obData, "limit", this.limit);
    }

    const url = this.offer.getURL("offers.prices", obData);
    const response = await this.offer
      .createRequest({ method: "GET", url, data: obData })
      .send();

    if (response) {
      const obData = response.getData();
      this.arPrices = map(obData.data, (obItem: Record<string, any>) => {
        const sValue = get(obItem, this.sPriceField);
        if (sValue) {
          let fValue = number(sValue);

          if (this.priceWithTax && !fValue) {
            fValue = number(obItem.price);
          }

          set(obItem, `${this.sPriceField}_value`, fValue);
          set(obItem, this.sPriceField, this.getCurrencyFormat(fValue));
        }

        return obItem;
      });
    }
  }

  getCurrencyFormat(fValue: number, sCode?: string | number) {
    fValue = number(fValue);

    // if (this.withTax && this.taxPercent) {
    //   const fPercentValue = percent(fValue, this.taxPercent);
    //   fValue = math.add(fValue, fPercentValue);
    // }

    if (!this.currency && !sCode) {
      return fValue;
    }

    if (!sCode && this.currency) {
      sCode = this.currency;
    }

    if (isNumber(sCode)) {
      const obCurrency = AppModule.currencies.find({ id: sCode });
      if (obCurrency) {
        sCode = obCurrency.code;
      }
    }

    return sCode && isString(sCode) ? currencyFormat(fValue, sCode) : fValue;
  }
}
</script>
